<template>
  <fieldset :disabled="disabled">
    <div class="animated fadeIn container novoLayout">
      <b-row>
        <b-col md="12" class="card" style="padding-top: 20px">
          <b-card no-body>
            <b-card-header> Grupo de Usuários </b-card-header>
            <b-card-body>
              <b-row>
                <b-col md="5">
                  <b-form-input
                    id="nome"
                    type="text"
                    placeholder="Nome do grupo"
                    v-model="nome"
                  />
                </b-col>
                <b-col md="2" offset-md="5" class="text-right">
                  <b-form-checkbox v-model="bo_ativo" switch
                    >Ativo</b-form-checkbox
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col md="5" class="mt-3 mb-3">
                  <b-form-checkbox
                    id="checkbox-1"
                    class="estiloCheckbock"
                    name="checkbox-1"
                    v-model="bo_restringirvisao"
                    unchecked-value="not_accepted"
                  >
                    <span
                      >Restringir visão a dados de outros usuários
                      <i
                        class="fa fa-question-circle"
                        title="Ao marcar, usuários desse grupo irão visualizar somente ações realizados pelo próprio usuário."
                      ></i>
                    </span>
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card no-body>
            <b-card-header> Configuração de Permissões </b-card-header>
            <b-card-body>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    id="config-tab"
                    data-toggle="tab"
                    href="#config"
                    role="tab"
                    aria-controls="config"
                    aria-selected="true"
                    >Configurações</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    id="usuarios-tab"
                    data-toggle="tab"
                    href="#usuarios"
                    role="tab"
                    aria-controls="usuarios"
                    aria-selected="false"
                    >Usuários</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="config"
                  role="tabpanel"
                  aria-labelledby="config-tab"
                >
                  <template v-for="menu in this.menus">
                    <div
                      class="accordion"
                      role="tablist"
                      :key="'accordion_' + menu.id"
                    >
                      <b-card no-body class="mb-0">
                        <b-card-header
                          header-tag="header"
                          class="p-1 headerPermissoes"
                          role="tab"
                        >
                          <div class="row">
                            <div
                              class="col-9"
                              v-b-toggle="'accordion_' + menu.id"
                            >
                              {{ menu.name }}
                            </div>
                            <div class="col-2 text-center">
                              <b-form-checkbox
                                @change="permissaoTotal(menu)"
                                v-model="menu.permissaoTotal"
                              >
                                <span style="font-weight: normal"
                                  >Permissão total</span
                                >
                              </b-form-checkbox>
                            </div>
                            <div
                              class="col-1 accordion-button"
                              v-b-toggle="'accordion_' + menu.id"
                            ></div>
                          </div>
                        </b-card-header>
                        <b-collapse
                          :id="'accordion_' + menu.id"
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <div class="row cabecalho">
                              <div class="col-9">Permissões</div>
                              <div class="col-1 text-center">Acessa</div>
                              <div class="col-1 text-center">Edita</div>
                              <div class="col-1"></div>
                            </div>
                            <div class="row" v-for="submenu in menu.submenus">
                              <div class="col-9">{{ submenu.name }}</div>
                              <div class="col-1 colBordaDireita text-center">
                                <b-form-checkbox
                                  switch
                                  :class="'cb_' + menu.id"
                                  @change="permissaoAcessa(submenu)"
                                  v-model="submenu.acessa"
                                ></b-form-checkbox>
                              </div>
                              <div class="col-1 text-center">
                                <b-form-checkbox
                                  switch
                                  :class="'cb_' + menu.id"
                                  @change="permissaoEdita(submenu)"
                                  v-model="submenu.edita"
                                ></b-form-checkbox>
                              </div>
                              <div class="col-1"></div>
                            </div>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                    </div>
                  </template>
                </div>
                <div
                  class="tab-pane fade usuarios"
                  id="usuarios"
                  role="tabpanel"
                  aria-labelledby="usuarios-tab"
                >
                  <div class="row cabecalho">
                    <div class="col-5">Usuários</div>
                    <div class="col-5 text-center">E-mail</div>
                    <div class="col-2 text-center">Status</div>
                  </div>
                  <div class="row" v-for="usuario in this.usuarios">
                    <div class="col-5">{{ usuario.first_name }}</div>
                    <div class="col-5">{{ usuario.email }}</div>
                    <div class="col-2 text-center">
                      <b-form-checkbox
                        switch
                        :class="'cb_' + usuario.id"
                        v-model="usuario.usuario_grupopermissao"
                      ></b-form-checkbox>
                      <output v-show="usuario.usuario_grupopermissao"
                        >Ativo</output
                      >
                      <output v-show="!usuario.usuario_grupopermissao"
                        >Inativo</output
                      >
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-card>
          <b-row>
            <b-col md="12" class="text-right mb-3">
              <b-button
                v-on:click="confirmDelete"
                variant="outline-danger"
                class="m-1"
              >
                Excluir
              </b-button>
              <b-button v-on:click="cancel" variant="outline-dark" class="m-1">
                Cancelar
              </b-button>
              <b-button
                v-on:click="saveGrupoPermissao"
                variant="outline-info"
                class="m-1"
              >
                Salvar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </fieldset>
</template>

<script>
import GrupoPermissao from "../../../services/grupoPermissao";
import "bootstrap/dist/js/bootstrap.min.js";
import { EventBus } from "../../../helpers/eventBus";

export default {
  name: "GrupoPermissaoForm",
  data: () => ({
    grupopermissao_id: 0,
    nome: "",
    bo_ativo: "",
    bo_restringirvisao: "",
    menus: [],
    usuarios: [],
    disabled: false,
  }),
  mounted() {
    this.validaPermissao();
    this.buscarMenus();
    this.buscarUsuarios();
    EventBus.$emit("finishLoad", {
      finish: true,
    });
  },
  beforeMount() {
    if (this.$route.params.id) this.loadGrupoPermissao(this.$route.params.id);
  },
  methods: {
    validaPermissao() {
      GrupoPermissao.getPermissao("/configuracao/grupo_permissao").then(
        (response) => {
          if (!response.data.data) this.$router.push("/");
          else this.disabled = response.data.data.edita != "1";
        }
      );
    },
    loadGrupoPermissao(id) {
      GrupoPermissao.getById(id)
        .then((response) => {
          if (
            response.data.data.revendas_codigo !=
            this.$store.state.login.resale.revenda_codigo
          ) {
            this.$router.push("/configuracao/grupo_permissao");
          }

          this.grupopermissao_id = response.data.data.id;
          this.nome = response.data.data.nome;
          if (response.data.data.bo_ativo == 1) this.bo_ativo = true;
          else this.bo_ativo = false;
          if (response.data.data.bo_restringirvisao == 1)
            this.bo_restringirvisao = true;
          else this.bo_restringirvisao = false;
          this.buscarMenus();
          this.buscarUsuarios();
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    buscarMenus() {
      GrupoPermissao.getMenus(this.grupopermissao_id)
        .then((response) => {
          this.menus = response.data.data;
          this.menus.forEach((menu) => {
            menu.permissaoTotal = true;
            menu.submenus.forEach((element) => {
              if (element.acessa == 1) {
                element.acessa = true;
              } else {
                element.acessa = false;
                menu.permissaoTotal = false;
              }
              if (element.edita == 1) {
                element.edita = true;
              } else {
                element.edita = false;
                menu.permissaoTotal = false;
              }
            });
          });
        })
        .catch((e) => {
          if (e.response.status === 404) return;
          this.$helper.showErrorResponse(e);
        });
    },
    buscarUsuarios() {
      GrupoPermissao.getUsuarios(this.grupopermissao_id)
        .then((response) => {
          this.usuarios = response.data.data;
          this.usuarios.forEach((element) => {
            if (element.usuario_grupopermissao > 0)
              element.usuario_grupopermissao = true;
            else element.usuario_grupopermissao = false;
          });
        })
        .catch((e) => {
          if (e.response.status === 404) return;
          this.$helper.showErrorResponse(e);
        });
    },
    saveGrupoPermissao() {
      let data = {
        id: this.grupopermissao_id,
        revenda_codigo: this.$store.state.login.resale.revenda_codigo,
        nome: this.nome,
        bo_ativo: this.bo_ativo,
        bo_restringirvisao: this.bo_restringirvisao,
        menus: this.menus,
        usuarios: this.usuarios,
      };
      GrupoPermissao.saveGrupoPermissao(data)
        .then((response) => {
          this.$router.push("/configuracao/grupo_permissao");
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    cancel() {
      this.$router.push("/configuracao/grupo_permissao");
    },
    confirmDelete() {
      this.$bvModal
        .msgBoxConfirm("Tem certeza que deseja excluir o Grupo?", {
          title: "Atenção",
          size: "sm",
          buttonSize: "md",
          okVariant: "outline-success",
          okTitle: "Sim",
          cancelTitle: "Não",
          cancelVariant: "outline-danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            GrupoPermissao.deleteGrupoPermissao(this.grupopermissao_id)
              .then((response) => {
                this.$helper.showMsg(response.data.msg, response.data.type);
                this.$router.push("/configuracao/grupo_permissao");
              })
              .catch((e) => {
                this.$helper.showErrorResponse(e);
              });
          }
        })
        .catch((err) => {
          this.$helper.showErrorResponse(err);
        });
    },
    permissaoTotal(menu) {
      this.$nextTick(() => {
        menu.submenus.forEach((submenu) => {
          submenu.edita = menu.permissaoTotal;
          submenu.acessa = menu.permissaoTotal;
        });
      });
    },
    permissaoEdita(submenu) {
      this.$nextTick(() => {
        if (submenu.edita) submenu.acessa = submenu.edita;
      });
    },
    permissaoAcessa(submenu) {
      this.$nextTick(() => {
        if (!submenu.acessa) submenu.edita = submenu.acessa;
      });
    },
  },
};
</script>

<style scoped>
.headerPermissoes {
  height: 50px;
  padding: 13px !important;
  font-weight: bold;
  background-color: #f8f8fa !important;
  color: #000 !important;
}

.accordion > .card {
  overflow: initial;
}

.accordion > .card:first-of-type {
  border-bottom: inset;
}

.cabecalho {
  font-weight: bold;
  color: #423292;
  border-bottom: 1px solid #c8ced3;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.colBordaDireita {
  border-right: 1px solid #c8ced3;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  display: block;
  margin-right: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23229'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.usuarios .row {
  border-bottom: 1px solid #efefef;
}

.usuarios .col {
  border-right: 1px solid #efefef;
}

.usuarios .custom-control {
  display: inline !important;
}

.novoLayout .card-header {
  background-color: #cfc7f3;
  color: #3634a3;
}

.accordion > .card[data-v-468087f6]:first-of-type {
  border-bottom: 0px;
}
</style>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: #4038b7 !important;
  background-color: #4038b7 !important;
}
</style>